<template>
  <v-dialog :value="value" @change="handleChange" persistent max-width="800px">
    <v-card>
      <v-app-bar flat>
        <v-card-title class="px-0">{{ __('Confirmação', 'leads') }}</v-card-title>

        <v-spacer />
        <v-btn icon @click="handleClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-form
        @submit.prevent="confirmRefuseLead"
        class="pa-2"
      >
        <ValidationObserver ref="refuse_form" v-slot="{ invalid }">
          <v-card-text>
            <v-alert
              dense
              type="error"
              v-if="lead_state && !isActionValid"
            >{{ __('Não é possível recusar uma lead no estado', 'leads') }} {{ lead_state.state.name }}</v-alert>
            <ValidationProvider
              name="refuse_lead.motive"
              rules="required"
              v-slot="{ errors }"
            >
              <v-radio-group
                v-model="refusal_motive"
                :label="__('Motivo', 'leads')"
                :error-messages="errors"
              >
                <v-radio
                  v-for="motive in motives"
                  :key="motive.external_code"
                  :label="motive.name"
                  :value="motive"
                ></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider
              name="refuse_lead.approve_justification"
              :rules="is_justification_required ? 'required|': '' + 'max:255'"
              v-slot="{ errors }"
            >
              <v-textarea
                v-model="approve_justification"
                :label="__('Justificativa', 'leads')"
                filled
                :error-messages="errors"
                :counter="255"
              ></v-textarea>
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn type="submit" text color="red" :disabled="invalid || !isActionValid">{{
              __('Recusar', 'leads')
            }}</v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { approveLead } from "@/services/lead";
import { integrators_ids } from "@/services/integrators";
import i18n from '@/mixins/i18n';

export default {
  name: "refuse-lead-dialog",
  mixins: [i18n],
  props: {
    value: Boolean,
    lead: Object
  },
  data: () => ({
    refusal_motive: null,
    approve_justification: null
  }),
  methods: {
    handleChange($event) {
      this.$emit("input", $event);
    },
    handleClose() {
      this.$emit("input", false);
      this.resetForm();
    },
    resetForm() {
      this.$refs.refuse_form.reset();

      this.refusal_motive = null;
      this.approve_justification = null;
    },
    confirmRefuseLead() {
      this.$store.dispatch("confirm_dialog/confirm", {
        title: "Tem certeza?",
        description: "Ao recusar a lead, esta ação não poderá ser desfeita.",
        positive_action: () => this.refuseLead(),
        set_error_message: (error) => this.dispatchErrorMessage(error)
      }); 
    },

    async refuseLead() {
      const validation = await this.$refs.refuse_form.validate();
      if (!validation) return;

      try {
        const response = await approveLead(
          this.lead.id,
          false,
          this.approve_justification,
          this.refusal_motive.external_code
        );
        this.$listeners.updateLead(response);

        this.$store.commit("sendMessage", {
          text: "Lead recusada com sucesso",
        });
        this.handleClose();
      } catch(error) {
        this.dispatchErrorMessage(error);
      }
    },
    dispatchErrorMessage(error) {
      if (error.response.status == 422) {
        this.$refs.refuse_form.setErrors(error.response.data.errors);
        this.$store.commit("sendMessage", {
          text: error.response.data.message,
          color: "red",
        });
      } else {
        this.$store.commit("sendMessage", {
          text: "Falha ao reprovar lead",
          color: "red",
        });
      }
    } 
  },
  computed: {
    lead_type() {
      return this.lead?.lead_type;
    },
    lead_state: function() {
      return this.lead?.default_service?.external_status;
    },
    action() {
      if (this.lead_type) { 
        return this.$store.getters['eps/getActions']
            .find(action => action.canonical == 'eps_failure_service_execution')
            ?.external_actions[this.lead_type];
      }

      return null;
    },
    motives() {
      if (this.action) {          
        const motives = this.action 
            ? this.action.motives
            : [];

        return motives;
      }

      return [];
    },
    isActionValid() {
      return this.lead.integrator_id != integrators_ids.eps || 
        (this.action?.states || []).some(state => state.external_code == this.lead_state?.external_code);
    },
    is_justification_required() {
      if (!this.refusal_motive) return false;

      return this.refusal_motive.needs_justification;
    }
  }
}
</script>

<style>

</style>