<template>
  <v-menu
    :value="value"
    @input="$emit('input', $event)"
    v-bind="$attrs"
    :close-on-content-click="false"
    offset-y
    left
  >
    <template v-slot:activator="{ on }">
      <v-badge
        :content="notifications_count"
        :value="notifications_count"
        color="red"
        overlap
      >
        <v-icon v-on="on">mdi-bell</v-icon>
      </v-badge>
    </template>
    <v-card width="500" max-height="500" :loading="loading">
      <v-app-bar elevation="0" color="white">
        <v-toolbar-title>{{
          __("Notificações", "notifications")
        }}</v-toolbar-title>
        <v-spacer />
        <a class="link" @click.stop.prevent="dismissAll">
          {{ __("Marcar todas como lidas", "notifications") }}
        </a>
      </v-app-bar>
      <v-divider></v-divider>
      <v-tabs v-model="tab">
        <v-tab>
          <v-badge :value="notifications_count" color="red" inline dot>
            <span>
              {{ __("Não lidas", "notifications") }}
            </span>
          </v-badge>
        </v-tab>
        <v-tab>
          {{ __("Lidas", "notifications") }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>

      <List
        :items="notifications"
        @click:item="$emit('input', false)"
        @click:dismiss="dismiss"
        hide-message
        dense
        checkable
      />
    </v-card>
  </v-menu>
</template>

<script>
import {
  indexByLeadRead,
  indexByLeadUnread,
  dismissByLead,
  dismissByLeadAndType,
} from "@/services/lead_notifications";
import List from "@/components/Lead/Notification/List";
import i18n from "@/mixins/i18n";

export default {
  name: "lead-notification-menu",
  components: { List },
  props: {
    value: Boolean,
  },
  mixins: [i18n],
  data() {
    return {
      pooling: null,
      loading: false,
      read_notifications: [],
      unread_notifications: [],
      tab: 0,
      tabs: [
        {
          text: this.__("Não lidas", "notifications"),
          value: "unread",
        },
        {
          text: this.__("Lidas", "notifications"),
          value: "read",
        },
      ],
    };
  },
  methods: {
    async getNotifications() {
      this.$store.commit("setLead", {
        ...this.lead,
        read_notifications: [],
        unread_notifications: [],
      });

      this.loading = true;

      try {
        const unreadResponse = await indexByLeadUnread(this.lead_number);
        const unread_notifications = unreadResponse.data;

        const readResponse = await indexByLeadRead(this.lead_number);
        const read_notifications = readResponse.data;

        this.$store.commit("setLead", {
          ...this.lead,
          read_notifications,
          unread_notifications,
        });
      } finally {
        this.loading = false;
      }
    },
    async dismiss(item) {
      this.loading = true;
      await dismissByLeadAndType(this.lead_number, item.type);
      this.loading = false;
    },
    async dismissAll() {
      this.loading = true;
      try {
        await dismissByLead(this.lead_number);
      } catch (e) {
        console.error(e);
        this.$store.commit("sendMessage", {
          text: this.__(
            "Falha ao marcar notificações como lidas",
            "notifications"
          ),
          color: "red",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    tab: function (value) {
      if (value === 1) {
        this.getNotifications();
      }
    },
  },
  computed: {
    lead_number() {
      return this.lead.identifier_code;
    },
    lead() {
      return this.$store.getters.getLead;
    },
    notifications_count() {
      return this.lead.unread_notifications?.length || 0;
    },
    notifications() {
      return this.tab === 0
        ? this.lead.unread_notifications
        : this.lead.read_notifications || [];
    },
  },
};
</script>
